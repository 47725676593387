import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent {
  active = 1;
  appointment:Appointment =  new Appointment();
  appointmentTypeList:any = [];
  departmentListDp:any = [];
  DoctorDpList:any = [];
  DateList:any = [];
  TimeList:any = [];
  @ViewChild("appointmentForm")
  appointmentForm!: NgForm;
  @ViewChild("appointmentFormFolloup")
  appointmentFormFolloup!: NgForm;
  isSubmitted:any = false;
  isSubmittedFolloup = false;
  btnLoading:boolean = false;
  findQuery:any;
  filterType:any = "";
  @Output("close") close: EventEmitter<any> = new EventEmitter();
  @Output("reload") reload: EventEmitter<any> = new EventEmitter();
  hideDropdown:boolean = true;
  loadingSpinnerDep:boolean = false;
  loadingSpinnerDoc:boolean = false;
  loadingSpinnerDate:boolean = false;
  loadingSpinnerTime:boolean = false;
  constructor(private toast:ToastrService,private localStorage:LocalStorageService,private api:AppointmentService) {}
  closeModal()
  {
     this.close.emit('close');
  }
  
  ngAfterContentInit()
  {
    this.loadDepartments()
  }
  findPaitendData()
  {
    let type = this.filterType;
    let query = this.findQuery
    let object:any = {}
    object[type] = query;
 
    let data = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      filter:object
    }
    ;
    this.api.getPatientBySearch(data).subscribe({
      next:(res) =>{
        if(res)
        {
          this.appointment = res;
          this.appointment.dept_id = ""
          this.appointment.patient_id = res.id;
        }
        else
        {
          this.toast.error("Please enter valid data")
        }
  
      },
      error:(e)=>{
        this.toast.error("Please enter valid data")
      }
    })
  }
  loadDepartments()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        isPublic:true,
      }
      
    }
   this.loadingSpinnerDep = true 
   this.api.getDepartments(param).subscribe({
    next:(response)=>{
     // console.log(response)
      this.departmentListDp = response
      this.loadingSpinnerDep = false 
    },
    error:(e)=>{
      this.loadingSpinnerDep = false 
    }
   })
  }
  loadDoctorsByDepartments(event:any)
  {
  
    if(event.target.value)
    {

   
    let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        dept_id:event.target.value,
       
        
    }
    this.loadingSpinnerDoc = true;
    this.api.getUserByDepartmentSchedule(param).subscribe({
      next:(response)=>{
       console.log(response)
        this.DoctorDpList = response
        this.loadingSpinnerDoc = false;
      },
      error:(e)=>{
        this.loadingSpinnerDoc = false;
      }
     })
    }
    else {
      this.DoctorDpList = []
      this.resetDropdowns();
      this.appointment.user_id = ""
    }
  }

  getType(event:any)
  {
    this.resetDropdowns();
    const user_id = event.target.value;
    if(user_id)
    {
   
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        }
    }
    
    this.api.getSchedule(param).subscribe({
      next:(response)=>{
       //console.log(response)
        const schedule = response.schedule;
          for (const key in schedule) {
            if (Object.prototype.hasOwnProperty.call(schedule, key)) {
              const element = schedule[key];
              if(element.isTrue)
              {
                this.appointmentTypeList.push(key)
              }
             // console.log(element)

            }
          }

        this.appointment.appointment_type = "";
      },
      error:(e)=>{
  
      }
     })
    }
    else
    {
      this.appointment.user_id = ""
    }

  }
  resetDropdowns()
  {
    this.appointmentTypeList = []
    this.DateList = [];
    this.TimeList = [];
    this.appointment.appointment_time = ""
    this.appointment.appointment_date = ""
    this.hideDropdown = true;

  }
  createFolloupAppointment(isValid:any)
  {
    this.isSubmittedFolloup = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.appointment.hospital_id = this.localStorage.getHospitalId()
      this.appointment.center_id = this.localStorage.getCenterId()
      this.appointment.check_in_type = "follow-up"
      this.appointment.appointment_source = "webapp"
      this.appointment.status = "booked"
      this.api.createAppointment(this.appointment).subscribe({
        next:(res)=>{
          this.btnLoading = false;
          this.reload.emit(true)
          this.closeModal();
          this.toast.success("Follow-Up Appointment created Successfully")
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
      console.log(this.appointment)
    }

  }
  saveAppointment(isValid:any)
  {

    this.isSubmitted = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.appointment.hospital_id = this.localStorage.getHospitalId()
      this.appointment.center_id = this.localStorage.getCenterId()
      this.appointment.check_in_type = "new"
      if(this.appointment.patient_id)
      {
        this.appointment.check_in_type = "follow-up"
        this.appointment.appointment_source = "webapp"
        this.appointment.status = "booked"
      }

      this.api.createAppointment(this.appointment).subscribe({
        next:(res)=>{
          this.btnLoading = false;
          this.reload.emit(true)
          this.closeModal();
          this.toast.success("Appointment created Successfully")
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
     // console.log(this.appointment)
    }

  }
  loadDates(event:any)
  {

    const type = event.target.value;
    let lo = type.toLowerCase()
    if(lo=="walk_in" || lo=="emergency" || lo=='' )
    {
  
      this.hideDropdown = true;
     
    }
    else
    {
      this.hideDropdown = false;
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:lo,
        timezone: "Asia/Calcutta",
        dateFormat: "DD-MM-YYYY"
    }
    this.loadingSpinnerDate = true;
        this.api.getAvailableDays(param).subscribe({
          next:(response)=>{
             console.log(response)
             this.DateList = response;
             this.loadingSpinnerDate = false;
          },
          error:(e)=>{
            this.loadingSpinnerDate = false;
          }
        })
    }
    
    
  }
  fnCheckCondition(item:any)
  {

    let flag:boolean = false;

    if((item.appointment_type!='walk_in') || item.appointment_type!='emergency')
    {
      flag = true;
    }
  
 
    
    return flag

  }
  loadSlots(event:any)
  {

    const date = event.target.value;
    if(date!="")
    {
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:this.appointment.appointment_type,
        appointment_date:date,
        timezone: "Asia/Calcutta",
        dateFormat: "DD-MM-YYYY"
    }
   // console.log(param)
   this.loadingSpinnerTime = true;
        this.api.getSlots(param).subscribe({
          next:(response)=>{
             console.log(response)
             this.TimeList = response;
             this.loadingSpinnerTime = false;
          },
          error:(e)=>{
            this.loadingSpinnerTime = false;
          }
        })
    }
    
    
  }
  removeSp(_string:any)
  {
   return _string.replace("_"," ")
  }
  getPatientRecord(event:any)
{
      this.appointment = event;
      this.appointment.dept_id = ""
      this.appointment.user_id = ""
      this.appointment.patient_id = event.id;
}
}




export class Appointment {
  fname: string = "";
  lname: string = "";
  patient_id!:Number;
  prefix: string = "";
  gender: string = "";
  user_id: string = "";
  age: string = "";
  uid_number:string = "";
  abha_number:string = "";
  blood_group:string = "";
  email: string = "";
  mobile: string = "";
  address: string = "";
  check_in_type:string = "new";
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  dept_id:string = "";
  client_id:string = ""
  hospital_id:Number = 0
  center_id:Number = 0;
  status:string = "booked";
}
