import { Component, ViewChild} from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeadService } from 'src/app/private/services/lead.service';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subcriber',
  templateUrl: './subcriber.component.html',
  styleUrls: ['./subcriber.component.scss']
})
export class SubcriberComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  btnLoading:boolean = false;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'tina';
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ['name','total','date','action'];
  dateObject:any = {startDate:"",endDate:""}
  lead: Lead = new Lead();
  @ViewChild("leadForm")
  leadForm!: NgForm;
  @ViewChild("leadFolloupForm")
  leadFolloupForm!: NgForm;
  leadsFollowList:any = [];
  deleteParams:any = {};

  constructor(private toast:ToastrService, private api:LeadService,private modalService: NgbModal, private localStorage:LocalStorageService){

    console.log(this.localStorage.getClientId())
  }
  ngAfterViewInit() {
    this.loadData();
  }
  ngOnInit(): void {}
  
  searchFilter(query:any)
  {
   this.searchQuery.setValue(query);
  }
  ResetDateFilter(event:any)
  {
    this.dateQuery.setValue("");
  }
  ResetFilter(event:any)
  {
    this.searchQuery.setValue("");
  }
  
  // load Initial table data from based on source type
  loadData() {
    let param = {
      where:{client_id: this.localStorage.getClientId()},
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      order:{col:'createdAt',dir:"desc"},
    }
    // if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    this.api.getAllSubscriberList(param).subscribe({
      next:(res: any)=>{
        console.log("customer data :",res);
        this.lead = res || {};
        this.dataSource = new MatTableDataSource<any>(res.rows);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  dateFilter(event:any)
  {
  this.dateQuery.setValue(event);
  }

  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  selectAll(event:any) {
    this.dataSource.data = this.dataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }));
  }

  deleteList() {
    this.btnLoading = true;
    this.api.deleteSubscriberList(this.deleteParams).subscribe({
      next:(res:any)=>{
        this.modalService.dismissAll();
        this.btnLoading = false;
        this.loadData();
        this.deleteParams = {};
        this.toast.success("Subscriber list deleted Successfully !!")
      },
      error:(e: any)=>{
        this.deleteParams = {};
        this.btnLoading = false;
      }
    })
  }

  subscriberOpen(content:any,item:any) {
    this.modalService.open(content, {  size:'sm', scrollable: false });
  }
}

export class Lead {
  name: string = "";
  total: string = "";
  client_id:string = ""
}
