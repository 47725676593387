import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient) { }

  getAllAppointmentWithCount(data:any): Observable<any> {
    return this.http.post(environment.getAllAppointmentWithCount,data);
  }
  
  getPatient(data:any): Observable<any> {
    return this.http.post(environment.getPatient,data);
  }

  getAllAppointment(data:any): Observable<any> {
    return this.http.post(environment.getAllAppointment,data);
  }
  getAllAppointmentByPatientId(data:any): Observable<any> {
    return this.http.post(environment.getAllAppointmentByPatientId,data);
  }
  getAllSchedule(data:any): Observable<any> {
    return this.http.post(environment.getAllSchedule,data);
  }
  getSchedule(data:any): Observable<any> {
    return this.http.post(environment.getSchedule,data);
  }
  getAvailableDays(data:any): Observable<any> {
    return this.http.post(environment.getAvailableDays,data);
  }
  getSlots(data:any): Observable<any> {
    return this.http.post(environment.getSlots,data);
  }
  
  createAppointment(data:any): Observable<any> {
    return this.http.post(environment.createAppointment,data);
  }
  updateAppointment(data:any): Observable<any> {
    return this.http.post(environment.updateAppointment,data);
  }
  updatePatient(data:any): Observable<any> {
    return this.http.post(environment.updatePatient,data);
  }
  
  
  getDepartments(data:any): Observable<any> {
    return this.http.post(environment.getAllDepartment,data);
  }

  getUsersByDepartments(data:any): Observable<any> {
    return this.http.post(environment.getUserByDepartment,data);
  }
  getPatientBySearch(data:any): Observable<any> {
    return this.http.post(environment.getPatientBySearch,data);
  }
  checkInOpdPatientData(data:any): Observable<any> {
    return this.http.post(environment.checkInOpdPatientData,data);
  }

  getUserByDepartmentSchedule(data:any): Observable<any> {
    return this.http.post(environment.getUserByDepartmentSchedule,data);
  }

  // importAppointments(data:any): Observable<any> {
  //   //return this.http.post(environment.importAppointments,data);
  // }
  
  
}
