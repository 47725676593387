import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeadService } from '../../services/lead.service';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { Toast, ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx'; 
import moment from 'moment';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent{
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  btnLoading:boolean = false;
  pageSizeOptions: number[] = [10, 25, 50];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'tina';
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["fname",'lname',"email","mobile","date","followup","status","action"];
  exportColumns: string[] = ["fname","lname","gender","email","mobile","date","followup","status","platform"];
  dateObject:any = {startDate:"",endDate:""}
  lead: Lead = new Lead();
  leadFolloup: LeadFollowup = new LeadFollowup();
  @ViewChild("leadForm")
  leadForm!: NgForm;
  @ViewChild("leadFolloupForm")
  leadFolloupForm!: NgForm;
  leadsFollowList:any = [];
  leadChannels:any = [];
  users: any = [];
  mergedArr: any = [];
  exportArrayData = [];
  LeadArrayData = [];
  temp:any = [];
  subsciberListName: any = "";
  customer = false;

  constructor(private toast:ToastrService, private api:LeadService,private modalService: NgbModal, private localStorage:LocalStorageService){

    //console.log(this.localStorage.getClientId())
    this.leadChannels = JSON.parse(JSON.stringify(this.localStorage.getLeadChannels()));
    if(this.customer) {
      this.displayedColumns = ["select","fname",'lname',"email","mobile","date","followup","status","action"];
    }

    //console.log(this.leadChannels)
  }
  ngAfterViewInit() {
    this.loadData();
  }
  ngOnInit(): void {}
  
  searchFilter(query:any)
  {
   this.searchQuery.setValue(query);
  }
  ResetDateFilter(event:any)
  {
    this.dateQuery.setValue("");
  }
  ResetFilter(event:any)
  {
    this.searchQuery.setValue("");
  }
  clearleadForm()
  {
    this.lead = new Lead();
  }
  
  // load Initial table data from based on source type
  loadData()
  {

  try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{leadChannel:this.currentTab,hospital_id:this.localStorage.getHospitalId()},
      filter:this.searchQuery.value,
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      order:{col:'createdAt',dir:"desc"},
    }
    if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}

    return  this.api.getAllData(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
    console.log(data);
    data.rows.forEach((e:any,i:any) => {
      let index = this.temp.findIndex((p:any) => {
        if(p.lead_id === e.lead_id){
          data.rows[i].isSelected = true;
        }
      });
    })
    this.dataSource = new MatTableDataSource<any>(data.rows);
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  dateFilter(event:any)
  {
  this.dateQuery.setValue(event);
  }
  followUps(content:any,element:any)
  {
    this.modalService.open(content, { size:'xl', scrollable: true});
    this.getLead(element.lead_id)
  }
  changeTab(tab:any)
  {
    this.dataSource.data = [];
    this.currentTab = tab;
    this.loadData();
  }
  edit(content:any,object:any){
    this.formAction = "update";
    this.modalService.open(content, { size:'xl', scrollable: true});
    this.getLead(object.lead_id)
   
  }
  getLead(id:any)
  {
    let param = {
      lead_id:id,
      hospital_id:this.localStorage.getHospitalId()
    }
    this.api.getLead(param).subscribe({
      next:(res)=>{
        //console.log(res)
        this.lead = res || {};
        this.leadsFollowList = res.leadsfollowups || [];
      },
      error(err) {
        
      },
    })
  }
  open(content:any) {
    this.formAction = "add";
    this.clearleadForm();
		this.modalService.open(content, { size:'xl', scrollable: true}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
    
			},
			(reason) => {
        
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  openList(content:any) {
    if(this.temp.length < 1){
      this.toast.error("Please select a record to create the list !");
    }
    else {
    this.formAction = "add";
		this.modalService.open(content, { size:'xl', scrollable: true}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
    
			},
			(reason) => {
        
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
    }
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  //save Lead Data
  SaveLead(isValid: any) {
    this.isSubmitted = true;
    
    if(isValid)
    {
      if(this.formAction == 'add')
      {
        this.btnLoading = true;
        this.lead.client_id = this.localStorage.getClientId();
        this.lead.hospital_id = this.localStorage.getHospitalId();
        this.lead.leadChannel = "manual";
        this.api.addLeadData(this.lead).subscribe({
          next:(c) =>{
            this.modalService.dismissAll()
            this.loadData()
            this.btnLoading = false;
            this.toast.success("Lead Added Successfully","Success!!")
          },
          error:(e) =>{
            this.btnLoading = false;
          },
          complete:()=>{}
        })
      }
      else if(this.formAction == 'update')
      {
       
        this.btnLoading = true;
        this.api.updateLead(this.lead).subscribe({
          next:(c) =>{
            this.modalService.dismissAll()
            this.loadData()
            this.isSubmitted = false
            this.btnLoading = false;
            this.toast.success("Lead Updated Successfully","Success!!")
            this.lead = new Lead();
          },
          error:(e) =>{
            this.btnLoading = false;
            this.isSubmitted = false
          },
          complete:()=>{}
        })
      }
     // console.log(this.lead)
    
    }

  }

  SaveLeadFollowUp(isValid: any)
  {
    this.isSubmitted = true
    if(isValid)
    {
      this.btnLoading = true;
      this.leadFolloup.lead_id = this.lead.lead_id;
      this.lead.hospital_id = this.localStorage.getHospitalId();
      this.api.addLeadFollowUp(this.leadFolloup).subscribe({
        next:(c) =>{
          this.getLead(this.lead.lead_id)
          this.btnLoading = false;
          this.isSubmitted = false
          this.toast.success("Lead Followup Added Successfully","Success!!")
        },
        error:(e) =>{
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete:()=>{}
      })
    }
    
  }
  export(header:any)
  {

    let excelRowData:any = [];
      this.exportArrayData.forEach((element:any) => {
        excelRowData.push({0:element.fname,1:element.lname,2:element.gender,3:element.email,4:element.mobile,5:element.date_of_lead,6:element.followup,7:element.leadStattus,8:element.leadChannel})
      });
      
      let excelData = [];
      let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
   // console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
  
      XLSX.writeFile(wb, "Leads_data_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
    
  
  }

  addSubscriberList(isValid: any){
    this.isSubmitted = true
    if(isValid) {
      this.btnLoading = true;
      let list_number:any = [];
      let list_email:any = [];
      console.log(this.temp)
      const list = this.temp.reduce((res: any, item: any) => {
        if(item.mobile) {
          list_number.push(item.mobile);
        }
        if(item.email) {
          list_email.push(item.email);
        }
        res.push({ lead_id: item.lead_id, client_id: item.client_id, fname: item.fname, lname: item.lname, name: item.name, mobile: item.mobile, email: item.email });
        return res;
      }, []);
      let params = {
        "subscriber_name": this.subsciberListName,
        "client_id": this.localStorage.getClientId(),
        "total_subscriber": this.temp.length,
        "subscriber_list": list,
        "subscriber_phones": list_number,
        "subscriber_emails": list_email
      };
      this.api.addSubcriberList(params).subscribe({
        next:(c) =>{
          this.modalService.dismissAll();
          this.temp = [];
          this.loadData();
          this.btnLoading = false;
          this.isSubmitted = false;
          this.toast.success("Subscriber List Added Successfully","Success!!");
        },
        error:(e) =>{
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete:()=>{}
      })
    }
  }

  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  hell(event:any, element: any) {
    if(element.isSelected == true) {
      this.temp.push(element);
    }
    if(element.isSelected == false) {
      console.log("unchecked---")
      this.temp = this.temp.filter(function( obj:any ) {
        return obj.lead_id !== element.lead_id;
    })
    }
    this.users = this.temp;
    this.mergedArr = this.temp;
    console.log("heloo3 ------------",this.temp);
  }

  selectAll(event:any) {
    if(event.checked == false) {
      console.log("false ------------");
      this.temp = [];
    }
    this.dataSource.data = this.dataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }));
    if(event.checked == true) {
      console.log("true ------------");
      this.temp = this.dataSource.data;
    }
    console.log("-- ",this.temp);
  }

}
function observableOf(arg0: null): any {
  console.log(arg0)
  throw new Error('Function not implemented.');
}

export class Lead {
  isSelected = 'isSelected';
  lead_id!: Number;
  fname: string = "";
  lname: string = "";
  leadsfollowup!:JSON;
  prefix: string = "";
  date_of_lead:String = moment().format("YYYY-MM-DD");
  time_of_lead:String = moment().format("HH:mm");
  gender: string = "";
  age: Number = 0;
  email: string = "";
  mobile: string = "";
  state: string = "";
  country: string = "";
  city: string = "";
  address: string = "";
  query: string = "";
  leadStattus:string = ""
  client_id:string = ""
  hospital_id:Number = 0
  leadChannel:String = ""
}
export class LeadFollowup {
  lead_id!: Number;
  followup_status: string = "";
  feedback: string = "";
  user_query: string = "";
  comment: string = "";
}

