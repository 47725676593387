import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  getAllData(data:any): Observable<any> {
    return this.http.post(environment.getAllLeads,data);
  }
  addLeadData(data:any): Observable<any> {
    return this.http.post(environment.createLead,data);
  }
  getLead(data:any): Observable<any> {
    return this.http.post(environment.getLead,data);
  }
  updateLead(data:any): Observable<any> {
    return this.http.post(environment.updateLead,data);
  }
  addLeadFollowUp(data:any): Observable<any> {
    return this.http.post(environment.createleadsfollowup,data);
  }
  addSubcriberList(data:any): Observable<any> {
    return this.http.post(environment.createSubsciberList,data);
  }
  getAllSubscriberList(data:any): Observable<any> {
    return this.http.post(environment.getAllSubsciberList,data);
  }
  deleteSubscriberList(data:any): Observable<any> {
    return this.http.post(environment.deleteSubsciberList,data);
  }
  getSubsciberList(data:any): Observable<any> {
    return this.http.post(environment.getSubsciberList,data);
  }
  getAllTemplates(data:any): Observable<any> {
    return this.http.post(environment.getAllTemplates,data);
  }

  addCampaignList(data:any): Observable<any> {
    return this.http.post(environment.createCampaignList,data);
  }

  updateCampaignList(data:any): Observable<any> {
    return this.http.post(environment.updateCampaignList,data);
  }

  getAllCampaignList(data:any): Observable<any> {
    return this.http.post(environment.getAllCampaignList,data);
  }
  deleteCampaignList(data:any): Observable<any> {
    return this.http.post(environment.deleteCampaignList,data);
  }

  sendWhatsappMsg(data:any): Observable<any> {
    return this.http.post(environment.sendWhatsappMsg,data);
  }

  sendMediaTemplateMsg(data:any): Observable<any> {
    return this.http.post(environment.sendMediaTemplateMsg,data);
  }

  sendTextTemplateMsg(data:any): Observable<any> {
    return this.http.post(environment.sendTextTemplateMsg,data);
  }

}
