//const BASE_URL = "http://localhost:3000/dev/"
const BASE_URL = "https://jxhgjdi24g.execute-api.us-east-1.amazonaws.com/dev/"
const UPLOAD_URL = "https://8x1pz40uv9.execute-api.us-east-1.amazonaws.com/dev/uploadfile/"

export const environment = {
    production: false,

    //Auth 
    loginUser:BASE_URL+"loginUser",
    forgotPassword:BASE_URL+"forgotPassword",
    resetPassword:BASE_URL+"resetPassword",
    getAnalytics:"https://zt4vunflkg.execute-api.us-east-1.amazonaws.com/test/getAnalytics",

    //Leads
    getAllLeads:BASE_URL+"getAllLeads",
    createLead:BASE_URL+"createLead",
    getLead:BASE_URL+"getLead",
    updateLead:BASE_URL+"updateLead",
    createleadsfollowup:BASE_URL+"createleadsfollowup",

    //Manage Users
    getAllUsers:BASE_URL+"getAllUsers",
    createUser:BASE_URL+"createUser",
    getUser:BASE_URL+"getUser",
    userModuleDropdownListing:BASE_URL+"userModuleDropdownListing",
    getUserByDepartment:BASE_URL+"getUserByDepartment",
    getUserByDepartmentSchedule:BASE_URL+"getUserByDepartmentSchedule",
    updateUser:BASE_URL+"updateUser",
    getAllCenter:BASE_URL+"getAllCenter",
    getAllDepartment:BASE_URL+"getAllDepartment",
    getAllRoles:BASE_URL+"getAllRoles",
  
    //Scheduled
    createSchedule:BASE_URL+"createSchedule",
    getSchedule:BASE_URL+"getSchedule",
    getAllSchedule:BASE_URL+"getAllSchedule",
    bulkSchedule:BASE_URL+"bulkSchedule",
    deleteSchedule:BASE_URL+"deleteSchedule",

    //Price Masters
    getAllPriceMasterWithCount:BASE_URL+"getAllPriceMasterWithCount",
    getAllPriceMaster:BASE_URL+"getAllPriceMaster",
    createPriceMaster:BASE_URL+"createPriceMaster",
    getPriceMaster:BASE_URL+"getPriceMaster",
    updatePriceMaster:BASE_URL+"updatePriceMaster",
    deletePriceMaster:BASE_URL+"deletePriceMaster",

    //Price Category 
    getAllPriceCategory:BASE_URL+"getAllPriceCategory",

    //Subscriber
    createSubscriberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createSubscriberList",
    createSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createSubscriberList",
    getSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getSubscriberlist",
    getAllSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getAllSubscriberList",
    deleteSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/deleteSubscriberList",
    
    //Campaign
    createCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createCampaignList",
    updateCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/updateCampaignList",
    getAllCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getAllCampaignList",
    deleteCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/deleteCampaignList",

    //template
    getAllTemplates: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/getAllTemplates",

    //msg
    sendWhatsappMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendWhatsappMsg",
    sendMediaTemplateMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendMediaTemplateMsg",
    sendTextTemplateMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendTextTemplateMsg",

    //Appointment
    getAllAppointmentWithCount:BASE_URL+"getAllAppointmentWithCount",
    createAppointment:BASE_URL+"createAppointment",
    getPatient:BASE_URL+"getPatient",
    getAllAppointment:BASE_URL+"getAllAppointment",
    getAllAppointmentByPatientId:BASE_URL+"getAllAppointmentByPatientId",
    getAvailableDays:BASE_URL+"getAvailableDays",
    getSlots:BASE_URL+"getSlots",
    updateAppointment:BASE_URL+"updateAppointment",
    checkInOpdPatientData:BASE_URL+"checkInOpdPatientData",

    //Patient Data
    getPatientBySearch:BASE_URL+"getPatientBySearch",
    upload:UPLOAD_URL,
    updatePatient:BASE_URL+"updatePatient",
    
    //Billings
    createBilling:BASE_URL+"createBilling",
    getBillingListByPatient:BASE_URL+"getBillingListByPatient",
    getReceiptData:BASE_URL+"getReceiptData",
    generateReceiptNo:BASE_URL+"generateReceiptNo",
    updateBilling:BASE_URL+"updateBilling",
    getBillingData:BASE_URL+"getBillingData",

    //Email config
    createEmailConfig:BASE_URL+"createEmailConfig",
    getEmailConfig:BASE_URL+"getEmailConfig",
    updateEmailConfig:BASE_URL+"updateEmailConfig",
    
    //Templates
    getAllPrimaryCheckupTemplate:BASE_URL+"getAllPrimaryCheckupTemplate",
    updatePrimaryCheckupTemplate:BASE_URL+"updatePrimaryCheckupTemplate",
    
    createMedicationTemplate:BASE_URL+"createMedicationTemplate",
    getMedicationTemplate:BASE_URL+"getMedicationTemplate",

    createInvestigationTemplate:BASE_URL+"createInvestigationTemplate",
    getInvestigationTemplate:BASE_URL+"getInvestigationTemplate",

    getAdviceTemplate:BASE_URL+"getAdviceTemplate",
    getDiagnosisTemplate:BASE_URL+"getDiagnosisTemplate",
    getMedicinesDiagnosisTemplate:BASE_URL+"getMedicinesDiagnosisTemplate",
    

    //opdchart
    createOpdChartData:BASE_URL+"createOpdChartData",
    getAllOpdChartData:BASE_URL+"getAllOpdChartData",
    updateOpdChartData:BASE_URL+"updateOpdChartData",

    //IPD
    getAllIpdAppointmentWithCount:BASE_URL+"getAllIpdAppointmentWithCount",
    
  };
  
  
