import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, filter, map, mergeMap, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() toggleMenuEvent = new EventEmitter<void>();  
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  profileName: any;
  currentTheme = 'default';
  currentTitle:any

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private localStorage:LocalStorageService,private router:Router,private activatedRoute:ActivatedRoute) {
    this.profileName = localStorage.getProfileName();
    this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route:any) => {
        let title = route.data['title'] || '';
        while (route.firstChild) {
          route = route.firstChild;
          title+= route.data['title'];
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event:any) => this.currentTitle = event['title']);
  }

  public toggleMenu() {
    this.toggleMenuEvent.emit();
} 
  ngOnInit() {
  
   // this.currentTitle = this.route.data['title'] ;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
  }

  toggleSidebar(): boolean {
    return false;
  }

  navigateHome() {
    return false;
  }
  logout()
  {
    this.localStorage.logout();
    this.router.navigate(['login'])
  }
}
