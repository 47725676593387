import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFilterComponent } from '../../ui/date-filter/date-filter.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  
  DateFilterComponent = DateFilterComponent;
  FilterdateStart:Date =  new Date();
  Filterdateend:Date =  new Date();
  filterType:any = "";
  SearchInput:any;
  errorMsg:any

  @Output("create") Create: EventEmitter<any> = new EventEmitter();
  @Output("ExportData") ExportData: EventEmitter<any> = new EventEmitter();
  @Output("ResetDateFilter") ResetDateFilter: EventEmitter<any> = new EventEmitter();
  @Output("ResetFilter") ResetFilter: EventEmitter<any> = new EventEmitter();
  @Output("DateFilter") DateFilter: EventEmitter<any> = new EventEmitter();
  @Output("searchFilter") searchFilter: EventEmitter<any> = new EventEmitter();
  @Input() title = "";
  @Input() isDatefilter = false;
  @Input() isExport = false;
  @Input() isAdd = false;
  @Input() filterArray:any = [];
  @Input() exportArray:any = [];
  check_true:boolean = false;
  filterCol:any = [];
  actionButton:any = "search"
  toggleExport:boolean = false;
  constructor(private toast:ToastrService) { }
  ngOnInit(): void {
  }
  toggleExportFn()
  {
    this.toggleExport = (this.toggleExport) ? false :  true
  }
  addFilterCol(event:any,i:any)
  {
       if(event.target.checked)
       {
        this.filterCol.push({key:i,value:event.target.value})
       }
       else
       {
        this.filterCol =  this.filterCol.filter(((item:any)=> item.key !=i))
       }
  }
  fnSelectAll(event:any)
  {
    var checkboxes:any = document.getElementsByClassName('filter_check');
       if(event.target.checked)
       {

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
            console.log(checkboxes.length)
              checkboxes[i].checked = true;
              this.filterCol.push({key:i,value:checkboxes[i].value})
          }
         }
       }
       else
       {
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
              checkboxes[i].checked = false;
          }
      }
        this.filterCol = [];
       }
  }
  changeFilter(event:any)
  {

  }
  resetFilter()
  {
    this.actionButton = 'search'; 
    this.SearchInput = ""
    this.filterType = ""
    this.searchFilter.emit("")
    this.ResetFilter.emit("reset")
  }
  createMethod()
  {
    this.Create.emit();
  }

  getFilterValue ()
  {
    let type = this.filterType;
    let query = this.SearchInput
    let object:any = {}

    if(type=="")
    {
      this.toast.error("Select Filter Type")
      this.errorMsg = "Select Filter Type"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
   
    }
  

    else
    {
      if(query=="" || query == undefined)
    {
      this.toast.error("Enter query")
      this.errorMsg = "Enter query"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
      this.searchFilter.emit('');
   
    }
    else
    {
      object[type] = query;
      this.searchFilter.emit(object);
      this.actionButton = 'reset'; 
    }

    }
 
  }
  exportExcel()
  {
    if(this.filterCol.length == 0)
    {
      this.toast.error("Please select column")
      return;
    }
    this.ExportData.emit(this.filterCol);
    this.toggleExport = false;
   
  }
  applyFilter(event: any) {
    let filterValue = event.target.value;
}
filterByDateRange()
{
  let start = (this.FilterdateStart!=null) ? new Date(this.FilterdateStart.setHours(0,0,0,0)) :this.FilterdateStart;;
  let end =  (this.Filterdateend!=null) ? new Date(this.Filterdateend.setHours(0,0,0,0)) :this.Filterdateend;

  let startd = moment(start).format("YYYY-MM-DD")
  let endb = moment(end).format("YYYY-MM-DD")
  if(startd!= "Invalid date" && endb!= "Invalid date")
  {
    let object = {startDate:startd,endDate:endb}
    this.DateFilter.emit(object)
  }

}
resetDatePicker()
{
  this.DateFilter.emit("")
  this.ResetDateFilter.emit("reset")
}




}
