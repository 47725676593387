import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
  })
  export class LocalStorageService {
    isLogin = false;
  
    role:any;
    profile_name:any;
    userId:any
    hospital_id:any;
    center_id:any;
    client_id:any;
    dashboard:any;
    specialty:any;
    permissions:any;
    appointment_config:any
    leadChannels:any;
    feature_modules:any;
    constructor( private router: Router) { }
  
    login(resp: any) {
     // console.log(resp)
      this.isLogin = true;
      this.role =  resp.role.role_name;
      localStorage.setItem("leadChannels", resp.hospital?.leadChannels || null);
      localStorage.setItem("appointment_config", resp.appointment_config  || null);
      localStorage.setItem("isproblemArea", resp.isproblemArea  || null);
      localStorage.setItem('center_id', resp.center_id);
      localStorage.setItem('dept_id', resp.dept_id);
      localStorage.setItem('hospital_id', resp.hospital_id);
      localStorage.setItem('role_id', resp.role_id);
      localStorage.setItem('client_id', resp.client_id);
      localStorage.setItem('user_id', resp.user_id);
      localStorage.setItem('profile_name', resp.fname+" "+resp.lname);
      localStorage.setItem('role', resp.role.role_name);
      localStorage.setItem('feature_modules', resp.hospital.feature_modules);
      localStorage.setItem('specialty', resp.hospital.specialty);
      localStorage.setItem('dateFormat', resp.hospital.dateFormat);
      localStorage.setItem('permissions', JSON.stringify(resp.role.permissions));
      localStorage.setItem('enablePrintLayout', resp.hospital.enablePrintLayout);
      let username = resp.username.replace(/([A-Z])/g, ' $1').trim();
      localStorage.setItem('username',username);
      if(resp.hospital.logo)
      {
        this.toDataUrl(resp.hospital.logo,this.getBase64Logo)
      }
      
      if(resp.hospital.enablePrintLayout)
      {
        this.toDataUrl(resp.hospital.printlayout.pre_header_image,this.getBase64HeaderPrescription)
        this.toDataUrl(resp.hospital.printlayout.pre_footer_image,this.getBase64FooterPrescription)
      }
      return { success: this.isLogin, role: this.role };
    }
  
    logout() {
      this.isLogin = false;
      this.role = '';
      localStorage.setItem("leadChannels","");
      localStorage.setItem("appointment_config", "");
      localStorage.setItem("isproblemArea", "");
      localStorage.setItem('center_id',"");
      localStorage.setItem('dept_id', "");
      localStorage.setItem('hospital_id', "");
      localStorage.setItem('role_id', "");
      localStorage.setItem('client_Id', "");
      localStorage.setItem('user_id', "");
      localStorage.setItem('profile_name', "");
      localStorage.setItem('role', "");
      localStorage.setItem('permissions', "");
      localStorage.setItem('specialty', "");
      localStorage.setItem('token', "");
      localStorage.setItem('username',"");
      localStorage.setItem('enablePrintLayout',"");
      localStorage.setItem('getBase64HeaderPrescription',"");
      localStorage.setItem('getBase64FooterPrescription',"");
      localStorage.setItem('getBase64logo',"");
      localStorage.setItem('feature_modules', "");
      return { success: this.isLogin, role: '' };
    }
  
    isLoggedIn() {
      const loggedIn = localStorage.getItem('isLogin');
      if (loggedIn == 'true')
        this.isLogin = true;
      else
        this.isLogin = false;
      return this.isLogin;
    }
    
    getLeadChannels() {
      this.leadChannels = localStorage.getItem('leadChannels');
      return this.leadChannels;
    }
    getFeatureModules()
    {
      this.feature_modules = localStorage.getItem('feature_modules');
      return this.feature_modules;
    }
    getRole() {
      this.role = localStorage.getItem('role');
      return this.role;
    }
    getSpecialty() {
      this.specialty = localStorage.getItem('specialty');
      return this.specialty;
    }
    getPermissions() {
      this.permissions = localStorage.getItem('permissions');
      return this.permissions;
    }
    getHospitalId() {
      this.hospital_id = localStorage.getItem('hospital_id');
      return this.hospital_id;
    }
    getCenterId() {
      this.center_id = localStorage.getItem('center_id');
      return this.center_id;
    }
    getClientId() {
      this.client_id = localStorage.getItem('client_id');
      return this.client_id;
    }

    getProfileName() {
      this.profile_name = localStorage.getItem('profile_name');
      return this.profile_name;
    }

    getUserId() {
      this.userId = localStorage.getItem('userId');
      return this.userId;
    }

    getAppointment_config()
    {
      this.appointment_config = localStorage.getItem('appointment_config');
      return this.appointment_config;
    }
    getDashboard() {
        this.dashboard = localStorage.getItem("dashboards")?.split(',');
        return this.dashboard;
      }
    
    getDashboardAccess(dashboardName:any)
    {
        let dashboard:any  =   localStorage.getItem("dashboards")?.split(',');
        console.log(dashboardName+"--"+dashboard)
        let isAuthenticated = false;
        if(dashboard)
        {
            return isAuthenticated =  dashboard.includes(dashboardName)
        }
      

    }
    getBase64HeaderPrescription(data:any){
      localStorage.setItem('getBase64HeaderPrescription', data)
    }
    getBase64FooterPrescription(data:any){
      localStorage.setItem('getBase64FooterPrescription', data)
    }
    getBase64Logo(data:any){
      localStorage.setItem('getBase64logo',data);
    }


    toDataUrl(url:any, callback:any) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
              callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  }

  checkFeatures(module:any)
  {
   let features =  JSON.parse(JSON.stringify(this.feature_modules));
   return (features.includes(module) ? true : false);
  }

  checkUserAccess(module_name:any,feature_name:any)
  {
        //console.log(this.permissions)
        let feature  = [];
        let module = [];
        module = this.permissions.filter((item:any)=>{
        return  item.module_name  == module_name;
        })
        if(module.length > 0)
        {
        feature = module[0]['features'].filter((item:any)=>{
          return  item.name  == feature_name;
          })
        }

        return (module[0] && feature[0] ) ? true : false;
  }
  
  }
