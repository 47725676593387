import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  LocalStorageService } from 'src/app/utils/LocalStorageService';
import { AuthService } from '../services/auth.service';
import { JWTTokenServiceService } from '../services/token.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userName: any;
  btnLoading:boolean = false;
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;
  isLoading = false;
  buttonTitle = "Login"
  constructor(private toastr: ToastrService,private localStorageService:LocalStorageService, private jwt:JWTTokenServiceService, private coreAuth:AuthService,private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        username: ['',[Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        password: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(40)]],
      }
    );
  }


   forgetPassword(){
    this.router.navigate(['forget-password']);
  }
  goToDashboard(){
    this.router.navigate(['dashboard/home']);
  }


  get formError(): any {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
   this.isLoading = true;
   this.buttonTitle = "Loading"
   this.coreAuth.loginUser(this.form.value).subscribe({
    next: (res) => {
      this.buttonTitle = "Login"
      this.isLoading = false;
      this.jwt.setToken(res.token);
      this.toastr.success('Login Successful', 'Welcome');
      this.localStorageService.login(res);
      this.router.navigate(['main/leads']);
    },
    error: (e) => {
      this.buttonTitle = "Login"
      this.isLoading = false;
    },
    complete: () => {
      this.buttonTitle = "Login"
      this.isLoading = false;
    }
}) 
  }
  forgetPass()
  {
     this.router.navigate(['forget']);
  }
}
