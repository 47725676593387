import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  appointment:Appointment =  new Appointment();
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  isSubmittedresCheduled:boolean = false;
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  DateList:any = [];
  TimeList:any = [];
  paymentDetail:any = {
    payment_status:"",
    remarks:""
  }
  @ViewChild("reappointmentForm")
  reappointmentForm!: NgForm;
  isSubmitted: boolean = false;
  exportArrayData:any = [];
  isOnlinePayment:boolean = false;
  displayedColumns: string[] = ["pId","name","date","time","check_in_type","type","source","status","action"];
  exportColumns:any = [];
  constructor(private router:Router, private toast:ToastrService, private api:AppointmentService,private modalService: NgbModal, private localStorage:LocalStorageService){
  }
  ngAfterViewInit() {
    this.loadData();
    
 
  }
  checkInOPD(item:any)
  {
    
    if(item.pId=='N/A' || item.appointment.opd_number=='N/A' || item.appointment.opd_number=='' )
    {
     
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        patient_id:item.id,
        app_id:item.appointment.app_id,
        pId:item.pId,
        opd_number:item.appointment.opd_number,
        status:"check_in",
        details:(this.isOnlinePayment) ? this.paymentDetail : "",
      }
      this.api.checkInOpdPatientData(param).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success('Patient Checked IN OPD')
            setTimeout(() => {
              this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
            }, 500);
          
          }
        },
        error:(e)=>{}
      })
    }
    else
    {
     // alert('else')
     this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
    }
   
  }
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }
  
    // load Initial table data from based on source type
    loadData()
    {
      try{
     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
     merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
     switchMap(()=>{
      let param = {
        where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
        filter:this.searchQuery.value,
        page:(this.paginator.pageIndex *  this.paginator.pageSize),
        limit:this.paginator.pageSize,
        order:[{col:'app_id',dir:"desc"},{col:'appointment_date',dir:"desc"},{col:'appointment_time',dir:"desc"}],
        
      }
      if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
      return  this.api.getAllAppointmentWithCount(param)
      .pipe(catchError(() => observableOf(null)));
     }),map((response:any)=>{
      if (response === null) {
        return [];
      }
   
      this.totalRows = response.count;
      return response
     })
     ).subscribe({
      next:(data) =>{
     // console.log(data)
    
      this.exportArrayData = data.rows;
      this.dataSource = new MatTableDataSource<any>(data.rows);
      if(!this.localStorage.checkFeatures('opd')){
        this.displayedColumns = this.displayedColumns.filter(item=> item!='opd')
      }
      if(!this.localStorage.checkFeatures('online_payment')){
        this.displayedColumns = this.displayedColumns.filter(item=> item!='payment')
        this.exportColumns = ["pId","Name","Date","Time","Check In","Visit","Source","Status"];
      }
      else
      {
        this.isOnlinePayment = true;
        if(this.isOnlinePayment)
    {
      this.exportColumns = ["pId","Name","Date","Time","Check In","Visit","Source","Amount","Status"];
    } else {
      this.exportColumns = ["pId","Name","Date","Time","Check In","Visit","Source","Status"];
    }
      }
      },
      error:(e)=>{
       console.error(e)
      },
      complete:()=>{
       
      }
     })
    }
    catch(e){
     console.error(e)
    }
    }
  searchFilter(query:any){

   // alert(query)
    this.searchQuery.setValue(query);
  }
  open(content:any){
  
    this.modalService.open(content, { size:'lg', scrollable: false}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

  }
   getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
  gotoPatient(item:any)
  {
    this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
  }

  
  
  sourceImage = [{
    name:"webapp",
    img:"create.png"
  },
  {
    name:"tina",
    img:"tina-social.png"
  },
  {
    name:"whatsapp",
    img:"logos_whatsapp-icon.png"
  },
  {
    name:"twitter",
    img:"logos_twitter.png"
  },
  {
    name:"facebook",
    img:"logos_facebook.png"
  },
  {
    name:"instagram",
    img:"instagram-icon.png"
  },
  {
    name:"youtube",
    img:"logos_youtube-icon.png"
  },
  {
    name:"messenger",
    img:"logos_messenger.png"
  }]
  getsourceImage(source:any)
  {
    let img:any ="";
  
    let tempImage:any =  this.sourceImage.filter((item:any) => {
     return item.name === source;
    })

    if(tempImage)
    {
       img = tempImage[0].img
    }else
    {
      img = "create.png"
    }
    return img;

  }

  openReschedule(content:any,item:any)
  {

   if(item.appointment.appointment_type=="walk_in" || item.appointment.appointment_type=="emergency"  )
   {
      this.toast.error("Reschedule is not available ")
   }
   else
   {
    this.appointment = item.appointment;
 
    this.loadDates(item)
    setTimeout(() => {
      this.loadTimes(item)
    }, 500);
    this.appointment.appointment_time = "";
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
       // this.deleteSchedule(item.schedule_id)
   
      }  
    }, (reason) => {  

    });  
   }


  
  }
  loadDates(item:any)
  {
    this.DateList = []
    let param = {
      where: {
        hospital_id:this.localStorage.getHospitalId(),
        user_id:this.appointment.user_id,
        center_id:this.localStorage.getCenterId(),
        dept_id:item.appointment.user.dept_id,
      },
      appointment_type:item.appointment.appointment_type,
      timezone: "Asia/Calcutta",
      dateFormat: "DD-MM-YYYY"
  }
      this.api.getAvailableDays(param).subscribe({
        next:(response)=>{
           //console.log(response)
           this.DateList = response;
           
         
        },
        error:(e)=>{

        }
      })
  }

  loadTimes(item:any)
  {
    this.TimeList = []
    let param = {
      where: {
        hospital_id:this.localStorage.getHospitalId(),
        user_id:item.appointment.user_id,
        center_id:this.localStorage.getCenterId(),
        dept_id:item.appointment.user.dept_id,
      },
      appointment_type:item.appointment.appointment_type,
      appointment_date:item.appointment.appointment_date,
      timezone: "Asia/Calcutta",
      dateFormat: "DD-MM-YYYY"
  }
  //console.log(param)
      this.api.getSlots(param).subscribe({
        next:(response)=>{
           console.log(item)
           this.TimeList = response;
      
        },
        error:(e)=>{

        }
      })
  }
  updateReschedule(isValid:any)
  {
    this.isSubmittedresCheduled = true;
    let param = {
      patient_id:this.appointment.patient_id,
      app_id:this.appointment.app_id,
      appointment_date:this.appointment.appointment_date,
      appointment_time:this.appointment.appointment_time,
      status:"rescheduled"
    } 
    //console.log(param)
    if(this.appointment.appointment_date!="" && this.appointment.appointment_time !="")
    {
      this.api.updateAppointment(param).subscribe({
        next:(res)=>{
          this.modalService.dismissAll();
          this.toast.success("Appointment Rescheduled Successfully")
          this.loadData()
        },
        error:(e)=>{
  
        }
      })
      console.log(this.appointment)
    }
    else
    {
      this.toast.error("Invalid Data")
    }
  
  }
 
  loadSlots(event:any)
  {

    const date = event.target.value;
    if(date!="")
    {
      console.log(this.appointment)
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.user.dept_id,
        },
        appointment_type:this.appointment.appointment_type,
        appointment_date:date,
        timezone: "Asia/Calcutta",
        dateFormat: "DD-MM-YYYY"
    }
    console.log(param)
        this.api.getSlots(param).subscribe({
          next:(response)=>{
             console.log(response)
             this.TimeList = response;
          },
          error:(e)=>{
  
          }
        })
    }
    
    
  }

  cancelAppointmentfn(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          status:"cancelled",
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.toast.success("Appointment Cancelled Successfully")
            this.loadData()
          },
          
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }

  checkOut(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          status:'check_out',
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.loadData()
            this.toast.success("Appointment Updated Successfully")
          },
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
  completeAppointmentfn(content:any,item:any)
  {
  
    if(this.isOnlinePayment){this.paymentDetail = item.appointment.details || ""}
 
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        if(this.isOnlinePayment)
        {
          if(this.paymentDetail.payment_status=="succeeded")
          {
            //console.log(this.paymentDetail);
            this.checkInOPD(item);
          }
          else
          {
            this.toast.error("No Payment done for this patient")
          }
        }
        else
        {
          this.checkInOPD(item);
        }
 
      }  
    }, (reason) => {  

    });  
  }

  onFileChange(args: any)
  {
    const target: DataTransfer = <DataTransfer>(args.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
    };
  }
  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
     let status = "";
     if( element.appointment.status=="booked")
     {
       status = element.appointment.status.replaceAll('booked','Booked')
     }
     else if( element.appointment.status=="check_in" )
     {
       status = element.appointment.status.replaceAll('check_in','Checked In')
     }
     else if( element.appointment.status=="rescheduled" )
     {
       status = element.appointment.status.replaceAll('rescheduled','Rescheduled')
     }
     else if( element.appointment.status=="cancelled")
     {
       status = element.appointment.status.replaceAll('cancelled','Cancelled')
     }
     else if( element.appointment.status=="no_show")
     {
       status = element.appointment.status.replaceAll('no_show','No Show')
     }
     
     if(this.isOnlinePayment)
     {
     excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.appointment.user.fname+" "+element.appointment.user.lname,4:element.appointment.appointment_date,5:element.appointment.appointment_time,6:element.appointment.check_in_type,7:element.appointment.appointment_type,8:element.appointment.appointment_source,9:element.appointment?.details?.payment_status || "",10:element.appointment?.details?.amount || "",11:status})
     } else
     {
      excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.appointment.user.fname+" "+element.appointment.user.lname,4:element.appointment.appointment_date,5:element.appointment.appointment_time,6:element.appointment.check_in_type,7:element.appointment.appointment_type,8:element.appointment.appointment_source,9:status})
     }

   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "Appointments_data_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }

  noShowAppointmentfn(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          status:"no-show",
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.toast.success("Appointment Updated Successfully")
            this.loadData()
          },
          
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
}
function observableOf(arg0: null): any {
  //throw new Error('Function not implemented.');
}

export class Appointment {
  app_id!: Number;
  user!:any;
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  status:string = "booked";
  user_id:string = "";
  patient_id:Number = 0;
  dept_id:string = "";
  client_id:string = ""
  check_in_type:string = "follow-up";
  opd_number:string = ""
  hospital_id:Number = 0
  center_id:Number = 0
}