import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './component/body/body.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { HeaderComponent } from './component/header/header.component';
import { RouterModule } from '@angular/router';
import { CustomRangePanelComponent, DateFilterComponent } from './ui/date-filter/date-filter.component';
import { HomeComponent } from '../modules/home/home.component';
import { AppointmentComponent } from '../modules/appointment/appointment.component';
import { LeadComponent } from '../modules/lead/lead.component';
import { UsersComponent } from '../modules/configuration/users/users.component';
import { materialModules } from './module';
import { ScheduleComponent } from '../modules/configuration/schedule/schedule.component';
import { CampaignManagementComponent } from '../modules/components/campaign-management/campaign-management.component';
import { SubcriberComponent } from '../modules/components/subcriber/subcriber.component';
import { CampaignComponent } from '../modules/components/campaign/campaign.component';
import { EmailSettingsComponent } from '../modules/configuration/email-settings/email-settings.component';
import { CreateAppointmentComponent } from '../modules/components/create-appointment/create-appointment.component';
import { AppointmentDetailComponent } from '../modules/components/appointment-detail/appointment-detail.component';
import { PatientComponent } from '../modules/components/patient/patient.component';

@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    DateFilterComponent,
    CustomRangePanelComponent,
    HomeComponent,
    LeadComponent,
    UsersComponent,
    CampaignManagementComponent,
    EmailSettingsComponent,
    SubcriberComponent,
    CampaignComponent,
    ScheduleComponent,
    AppointmentComponent,
    CreateAppointmentComponent,
    AppointmentDetailComponent,
    PatientComponent
  ],
  imports: [
    CommonModule,
    RouterModule, 
    ...materialModules

  ]
  ,
  exports:[
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    
  ]
})
export class SharedModule { }
