import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { MainComponent } from './private/layout/main/main.component';
import { HomeComponent } from './private/modules/home/home.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { LeadComponent } from './private/modules/lead/lead.component';
import { UsersComponent } from './private/modules/configuration/users/users.component';
import { AuthGuard } from './utils/auth.guard';
import { ScheduleComponent } from './private/modules/configuration/schedule/schedule.component';
import { EmailSettingsComponent } from './private/modules/configuration/email-settings/email-settings.component';
import { CampaignManagementComponent } from './private/modules/components/campaign-management/campaign-management.component'; 

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full",
    title: "TINA"
  },
  {
    path: 'login',
    component: LoginComponent,
    title: "TINA"
  },
  {
    path: 'forget-password',
    component: ForgetComponent,
    title: "Forget Password"
  },
  {
    path: 'reset-password/:type/:token',
    component: ResetComponent,
    title: "Reset Password"
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children:[{
      path:"",
      data:{
        title:"Manage Leads"
      },
      component:LeadComponent,
      canActivate: [AuthGuard],
    },{
      path: 'home',
      component: HomeComponent,
      title: "Home",
      data:{
        title:"Analytics Dashboard"
      },
      canActivate: [AuthGuard]
     },{
      path: 'leads',
      component: LeadComponent,
      canActivate: [AuthGuard],
      title: "Leads",
      data:{
        title:"Manage Leads"
      }
      
      
     },
     {
      path: 'configuration-users',
      component: UsersComponent,
      title: "Users",
      canActivate: [AuthGuard],
      data:{
        title:"Manage Users"
      }
     },
     {
      path: 'campaign',
      component: CampaignManagementComponent,
      title: "Campaign",
      data:{
        title:"Campaign Management"
      }
     },
     {
      path: 'configuration-email',
      component: EmailSettingsComponent,
      canActivate: [AuthGuard],
      title: "Email",
      data:{
        title:"Email Settings"
      }
     },
     {
      path: 'configuration-schedule',
      component: ScheduleComponent,
      canActivate: [AuthGuard],
      title: "Schedule",
      data:{
        title:"Schedule Settings"
      }
     },
     {
      path: 'appointments',
      loadChildren: () => import('./private/modules/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuard],
      title: "Appointments",
      data:{
        title:"Manage Appointments"
      }
     },
  ]
    
  },
  { path: '**', 
  pathMatch: 'full', 
  component: LoginComponent 
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
