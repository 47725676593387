import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppCookieService } from 'src/app/utils/AppCookieService';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,private localStorage:LocalStorageService,private cookies:AppCookieService ) { }

  loginUser(data:any): Observable<any> {
    return this.http.post(environment.loginUser,data);
  }
  forgetPassword(data:any): Observable<any> {
    return this.http.post(environment.forgotPassword,data);
  }
  resetPassword(data:any): Observable<any> {
    return this.http.post(environment.resetPassword,data);
  }
  logOut()
  {
    this.cookies.remove("token")
    this.localStorage.logout();
  }

}