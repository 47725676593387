import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { MainComponent } from './private/layout/main/main.component';
import { SharedModule } from './private/shared/shared.module';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { HttpResponseInterceptor } from './utils/HttpResponseInterceptor';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './utils/GlobalErrorHandler';
import { ButtonComponent } from './components/button/button.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerModule, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import { AsyncPipe } from '@angular/common';

const materialModules = [
  FormsModule,
  ReactiveFormsModule,
  MatButtonModule,
  MatCheckboxModule,
  NgbModule,
  ToastrModule.forRoot({ timeOut: 1500,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,}),
  MatFormFieldModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSidenavModule,
  MatDividerModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatListModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule ,
  MatSortModule,
  AsyncPipe,
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetComponent,
    ResetComponent,
    MainComponent,
    ButtonComponent
    ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    materialModules,
 
 
  

   
    
  
  ],
  providers: [MatPaginatorModule,NgbModule,
    [{provide: ErrorHandler,useClass: GlobalErrorHandler},
      { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi:true}
    ],
    // if deployed on subdomain
    // {provide: LocationStrategy, useClass: HashLocationStrategy},

    MatRangeDateSelectionModel,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
