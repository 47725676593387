import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],


})
export class MainComponent {
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @Output("toggleMenu") toggleMenu: EventEmitter<any> = new EventEmitter();
  constructor(private jwt:JWTTokenServiceService,)
  {
    console.log(jwt.isTokenExpired())
    //console.log(jwt.getDecodeToken())
  } 
  

}
