import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
[x: string]: any;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  formAction = 'add'
  displayedColumns: string[] = ["username","fname",'lname',"email","role","date","status","action"];
  user:User = new User();
  @ViewChild("userForm")
  userForm!: NgForm;
  isSubmitted:boolean = false
  centers:any;
  roles:any;
  departmentsList:any;
 
  constructor(private toast:ToastrService, private api:UsersService, private modalService: NgbModal, private localStorage:LocalStorageService){

  }
  ngOnInit(): void {}

  getDropdownList()
  {
    this.api.userModuleDropdownListing().subscribe({
      next:(res)=>{
      this.centers = res.centers || []
      this.roles = res.roles || []
      this.departmentsList = res.departments || []
      },
      error:(e)=>{

      }
    })
  }

  getCenters()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
      }
      
    }
    this.api.getAllCenter(param).subscribe({
      next:(res)=>{
      this.centers = res;
      }
    })
  }
  loadDepartments()
  {
    let param = {
      where:{
        center_id:this.user.center_id,
        hospital_id:this.localStorage.getHospitalId(),
      }
      
    }
    this.api.getAllDepartment(param).subscribe({
      next:(res)=>{
      this.departmentsList = res;
      }
    })
  }

  loadRoles()
  {
    let param = {
      where:{
        center_id:this.user.center_id,
        hospital_id:this.localStorage.getHospitalId(),
      }
      
    }
    this.api.getAllRoles(param).subscribe({
      next:(res)=>{
      this.roles = res;
      }
    })
  }
  ngAfterViewInit()
  {
   this.loadData();
  }
  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      filter:this.searchQuery.value,
    }
    return  this.api.getAllData(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
    this.dataSource = new MatTableDataSource<any>(data.rows);
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  searchFilter(query:any)
  {

  }
  open(content:any)
  {
    this.user = new User();
    this.getCenters();
    this.formAction = "add";
		this.modalService.open(content, { size:'xl', scrollable: true})
	
  }
  edit(content:any,row:any)
  {
    setTimeout(() => {
      this.getCenters();
      setTimeout(() => {
        this.loadDepartments();
        setTimeout(() => {
          this.loadRoles();
        }, 1000); 
      }, 1000);  
    }, 1000);
    
   

    this.formAction = "edit";
    this.modalService.open(content, { size:'xl', scrollable: true})
    let param = {
      user_id:row.user_id
    }
    this.api.getUser(param).subscribe({
      next:(res)=>{
         this.user = res;
      },
      error:(e)=>{

      }
    })

  }
  saveUser(isValid:any)
  {
    this.isSubmitted = true;
    if(isValid)
    {
      this.user.hospital_id = this.localStorage.getHospitalId();
      this.user.client_id = this.localStorage.getClientId();
      if(this.formAction == 'add')
      {
       this.api.createUser(this.user).subscribe({
        next:(res)=>{
         this.loadData();
         this.modalService.dismissAll();
         this.toast.success("User Added Successfully",'Success')
        },
        error:(e)=>{  
          this.toast.error(e.error.msg,'Error')
        }
       })
      }
      else
      {
        this.api.updateUser(this.user).subscribe({
          next:(res:any)=>{
           this.loadData();
           this.modalService.dismissAll();
           this.toast.success("User Updated Successfully",'Success')
          },
          error:(e:any)=>{  
            this.toast.error(e.error.msg,'Error')
          }
         })
      }
    }
  }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

export class User {
  user_id!: Number;
  prefix: string = "";
  fname: string = "";
  lname: string = "";
  email: string = "";
  password!: JSON;
  username:String = "";
  gender:String = "";
  picture:String = "";
  profile:String = "";
  twoFactorAuthentication:String = "";
  status:String = "";
  client_id:String = "";
  hospital_id:Number = 0;
  center_id:Number = 0;
  dept_id:Number = 0;
  role_id:Number = 0;


}
