import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { UploadService } from 'src/app/private/services/upload.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent {
  @Input() patient:any;
  imgUpload:boolean = false;
  btnUpdate:boolean = false;
  imgTitle:any = "Upload Image"
  file:any ;
  url:any = '' || 'assets/img/no-image.jpg';
  constructor(private uploadService:UploadService, private toast:ToastrService, private api:AppointmentService , private localstorage:LocalStorageService) {

  }
  ngAfterViewInit() {
   this.url = this.patient.photo;
  }
  onFileSelected(event:any)
  {

    const files = event.target.files;
    if (files.length === 0)
        return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        this.toast.error('Only images are supported.');
        return;
    }

    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 

        this.patient.photo = reader.result; 
    }
  
  }

  uploadImage()
  {
  
    if (this.file) {
      console.log(  this.file)
       this.imgUpload = true;
       this.imgTitle = "Uploading.."
        let name =   this.file.name;
        const formData = new FormData();
        formData.append("file", this.file, this.patient.pId);
        formData.append("folders", "public/profileImages/");
        formData.append("bucket", "emruploads");
        console.log(formData)
        this.uploadService.uploadPatientProfile(formData).subscribe({
          next:(res)=>{
           console.log(res)
           if(res)
           {
      
          
            let param = {
              id:this.patient.id,
              hospital_id:this.patient.hospital_id,
              center_id:this.patient.center_id,
              photo:res.Location

            }
            this.patient.photo = res.Location;
            this.api.updatePatient(param).subscribe({
              next:(res:any)=>{
                if(res)
                {
                  this.imgUpload = false;
                  this.toast.success("Profile Uploaded Successfully")
                }
              }
            })
            this.imgTitle = "Upload Image"
           }
          },
          error:(e)=>{
            this.imgTitle = "Upload Image"
          }
        })
        //const upload$ = this.http.post("/api/thumbnail-upload", formData);
       // upload$.subscribe();

    }
  }
  updateData()
  {
    this.btnUpdate = true
    this.api.updatePatient(this.patient).subscribe({
      next:(res:any)=>{
        if(res)
        {
          this.btnUpdate = false;
          this.toast.success("Profile Uploaded Successfully")
        }
      },
      error:(e)=>{
        this.btnUpdate = false
      }
    })
  }
}



